<template>
  <transition-group name="opacity">
    <div
      v-for="(popup, index) in popups"
      :key="'pp-' + index"
      class="modal"
      :class="{
        'active': popup.isOpen
      }"
    >
      <div
        v-click-outside="($event) => tryClosePopup($event, ''+index, !popup.data?.noClose)"
        class="popup"
        :class="{
          'popup-small': popup.small || popup.data?.small,
          'popup-big': popup.big || popup.data?.big,
          ['' + popup.class]: popup.class,
          ['' + popup.data?.class || '']: popup.data?.class,
        }"
      >
        <div
          v-if="!popup.data?.noClose"
          class="close-button d-flex justify-content-end mb-10"
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="closePopup('' + index)"
          >
            <path
              d="M1.4 10.0004L0 8.60039L3.6 5.00039L0 1.42539L1.4 0.0253906L5 3.62539L8.575 0.0253906L9.975 1.42539L6.375 5.00039L9.975 8.60039L8.575 10.0004L5 6.40039L1.4 10.0004Z"
              fill="#555555"
            />
          </svg>
        </div>
        <AsyncPopupItem
          :component="popup.component"
          :data="popup.data || {}"
          @ready="openPopup(''+index)"
          @close="closePopup(''+index)"
        />
      </div>
    </div>
  </transition-group>
</template>
<script setup>
import AsyncPopupItem from "@/components/utils/AsyncPopupItem.vue";
import {inject, nextTick, watch} from "vue";
import {DisableBodyScroll, EnableBodyScroll} from "@/utils/bodyActions";
import {ClickOutside} from "@/utils/directives/clickOutside";
import {storeToRefs} from "pinia";
import {notificationsStore} from "@/store/notifications";

const { popups } = storeToRefs(notificationsStore());

const vClickOutside = ClickOutside

/** @var {NotificationsLayer} notificationsLayer */
const notificationsLayer = inject('NotificationsLayer')

watch(popups, (neoVal) => {
  if(neoVal && Object.keys(neoVal).length > 0){
    DisableBodyScroll()
  }
  else{
    EnableBodyScroll()
  }
}, {
  deep: true
})

const openPopup = (key) => {
  if(key && popups.value[key]){
    popups.value[key].isOpen = true;
    setTimeout(() => {
      popups.value[key].canClose = true;
    }, 20)
  }
}

const tryClosePopup = (event, key, canClose) => {
  if(!canClose){
    return;
  }
  if(
    event?.target?.closest('#jivo-iframe-container') ||
    event?.target?.closest('#jcont')
  ){
    return;
  }

  nextTick(() => {
    if(key && popups.value[key]){
      if(!popups.value[key].isOpen || !popups.value[key].canClose){
        return
      }
      closePopup(key);
    }
  })
}

const closePopup = (key) => {
  if(key && popups.value[key]){
    popups.value[key].isOpen = false;
    notificationsLayer.removePopup(key);
  }
}

</script>
