import { createRouter, createWebHistory } from 'vue-router'
import { dashboardStore } from '@/store/dashboard'
import { getActivePinia } from 'pinia'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'Default',
      title: 'CreditBooster - Главная',
      auth: false,
      name: 'Главная'
    },
    component: () =>
      import(/* webpackChunkName: 'home' */ '../views/auth/SignupView.vue')
  },
  {
    path: '/client-exist',
    name: 'clientExist',
    meta: {
      layout: 'Default',
      title: 'Клиент существует',
      auth: true,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/ClientExistView.vue')
  },
  {
    path: '/profile/identity',
    name: 'profileIdentity',
    meta: {
      layout: 'Default',
      title: 'Идентификация профиля',
      auth: true,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/ProfileIdentificationView.vue')
  },
  {
    path: '/profile/order',
    name: 'profileOrder',
    meta: {
      layout: 'Default',
      title: 'Подтверждение заявки профиля',
      auth: true,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/ProfileOrderView.vue')
  },
  {
    path: '/profile/checkOrder',
    name: 'profileCheckOrder',
    meta: {
      layout: 'Dashboard',
      title: 'Подтверждение заявки профиля',
      auth: true,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/ProfileCheckOrderView.vue')
  },
  {
    path: '/profile/confirmOrder',
    name: 'confirmOrder',
    meta: {
      layout: 'Dashboard',
      title: 'Одобрение заявки',
      auth: true,

    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/ProfileContractConfirmView.vue')
  },
  {
    path: '/profile/sendingMoney',
    name: 'sendingMoney',
    meta: {
      layout: 'Default',
      title: 'Отправка денег',
      auth: true,

    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/SendingMoneyView.vue')
  },
  {
    path: '/profile/declineOrder',
    name: 'declineOrder',
    meta: {
      layout: 'Dashboard',
      title: 'Отказ по заявке',
      auth: true,

    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/DeclineOrderView.vue')
  },
  {
    path: '/pre-confirmation',
    name: 'pre-confirmation',
    meta: {
      layout: 'Default',
      title: 'CreditBooster - Главная',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/CheckUserOrder.vue')
  },
  {
    path: '/confirm-phone',
    name: 'confirmPhone',
    meta: {
      layout: 'Default',
      title: 'Подтверждение смс',
      auth: true,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/auth/ConfirmPhoneView.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    meta: {
      layout: 'Default',
      title: 'CreditBooster - Подтверждение телефона',
      auth: false,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/auth/SignupView.vue')
  },
  {
    path: '/signin',
    name: 'signin',
    meta: {
      layout: 'Default',
      title: 'CreditBooster - Авторизация',
      auth: false,
      name: 'Авторизация'
    },
    component: () =>
      import(/* webpackChunkName: 'signin' */ '../views/auth/SigninView.vue')
  },
  {
    path: '/email/reset',
    name: 'emailReset',
    meta: {
      layout: 'Default',
      title: 'CreditBooster - Авторизация',
      auth: false,
      name: 'Авторизация'
    },
    component: () =>
      import(/* webpackChunkName: 'signin' */ '../views/auth/EmailResetView.vue')
  },
  {
    path: '/reset',
    name: 'reset',
    meta: {
      layout: 'Default',
      title: 'CreditBooster - Восстановление пароля',
      auth: false,
      name: 'Восстановление пароля'
    },
    component: () =>
      import(/* webpackChunkName: 'signin' */ '../views/ResetView.vue')
  },
  {
    path: '/pay/callback',
    name: 'pay/callback',
    meta: {
      layout: 'Default',
      title: '',
      auth: false,
      name: 'callback'
    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/PayCallback.vue')
  },
  {
    path: '/pay/loanpay',
    name: 'pay/loanpay',
    meta: {
      layout: 'Default',
      title: '',
      auth: false,
      name: 'callback'
    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/LoanPay.vue')
  },
  {
    path: '/confirm-debt',
    name: 'confirm-debt',
    meta: {
      layout: 'Default',
      title: '',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/ConfirmDebt.vue')
  },
  {
    path: '/loan',
    name: 'loanlist',
    meta: {
      layout: 'Dashboard',
      title: 'CreditBooster - Текущий займ',
      auth: true,
      name: 'Текущий займ'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../views/LoanListView.vue')
  },
  {
    path: '/cards',
    name: 'cards',
    meta: {
      layout: 'Dashboard',
      title: 'CreditBooster - Мои карты',
      auth: true,
      name: 'Мои карта'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../views/CardsView.vue')
  },
  {
    path: '/history',
    name: 'history',
    meta: {
      layout: 'Dashboard',
      title: 'CreditBooster - История займов',
      auth: true,
      name: 'История займов'
    },
    component: () =>
      import(/* webpackChunkName: 'history' */ '../views/HistoryView.vue')
  },
  {
    path: '/person',
    name: 'person',
    meta: {
      layout: 'Dashboard',
      title: 'Личный кабинет',
      auth: true,
      name: 'Личный кабинет'
    },
    component: () =>
      import(/* webpackChunkName: 'history' */ '../views/PersonView.vue')
  },
  {
    path: '/documents',
    name: 'documents',
    meta: {
      layout: 'Dashboard',
      title: 'CreditBooster - Документы',
      auth: true,
      name: 'Документы'
    },
    component: () =>
      import(/* webpackChunkName: 'documents' */ '../views/DocumentsView.vue')
  },
  {
    path: '/files',
    name: 'files',
    meta: {
      layout: 'Dashboard',
      title: 'CreditBooster - Файлы',
      auth: true,
      name: 'Файлы'
    },
    component: () =>
      import(/* webpackChunkName: 'files' */ '../views/FilesView.vue')
  },
  {
    path: '/tickets',
    name: 'tickets',
    meta: {
      layout: 'Dashboard',
      title: 'CreditBooster - Мои обращения',
      auth: true,
      name: 'Обращения'
    },
    component: () =>
      import(/* webpackChunkName: 'files' */ '../views/TicketsView.vue')
  },
  {
    path: '/appeal',
    name: 'appeal',
    meta: {
      layout: 'Dashboard',
      title: 'CreditBooster - Создать обращение',
      auth: true,
      name: 'Обращения'
    },
    component: () =>
      import(/* webpackChunkName: 'files' */ '../views/CreateTicketView.vue')
  },
  {
    path: '/qr',
    name: 'qr',
    meta: {
      layout: 'Default',
      title: 'CreditBooster - Файлы',
      auth: false,
      name: 'Файлы'
    },
    component: () =>
      import(/* webpackChunkName: 'qr' */ '../views/QrView.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'dynamicPage',
    meta: {
      layout: 'Default',
      title: 'Qreditka',
      auth: false,
      name: ''
    },
    component: () =>
      import(/* webpackChunkName: 'home' */ '../views/DynamicPage.vue')
  },
  {
    path: '/email/verify',
    name: 'verify_email',
    meta: {
      layout: 'Default',
      title: 'CreditBooster - Подтверждение Email адреса',
      auth: false,
      name: 'Подтверждение Email адреса'
    },
    component: () =>
      import('../views/EmailVerify.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const currentQuery = { ...to.query }

  const tokenFromCRM = from.query.token || to.query.token
  const userFromCRM = from.query.user_id || to.query.user_id
  const fromCRM = from.query.from_crm || to.query.from_crm

  const newQuery = { ...currentQuery }

  if (fromCRM == 'true' && tokenFromCRM && userFromCRM) {
    const pinia = getActivePinia()
    const dashboard = dashboardStore(pinia)

    dashboard.$patch({ admin: true })
    localStorage.setItem('admin', true)

  }

  next()
})

export default router
