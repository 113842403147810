import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import Maska from 'maska'
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import { createPinia } from 'pinia'
import axios from 'axios'

import i18n from '@/utils/i18n'
import {DashboardLayer} from "@/utils/store/dashboardLayer";
import {SignupLayer} from "@/utils/store/signupLayer";
import {NotificationsLayer} from "@/utils/store/notificationsLayer";
window.Pusher = Pusher

const app = createApp(App)
const pinia = createPinia();

app.use(pinia)

const notificationsLayer = new NotificationsLayer()
const dashboardLayer = new DashboardLayer()
const signupLayer = new SignupLayer()

notificationsLayer.install(app)
dashboardLayer.install(app)
dashboardLayer.setNotificationLayer(notificationsLayer)

signupLayer.install(app)
signupLayer.setDashboardLayer(dashboardLayer)
signupLayer.setNotificationLayer(notificationsLayer)

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  encrypted: true,
  authEndpoint: `${process.env.VUE_APP_API_URL}api/broadcasting/auth`,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/broadcasting/auth`,
            {
              // eslint-disable-next-line camelcase
              socket_id: socketId,
              // eslint-disable-next-line camelcase
              channel_name: channel.name
            },
            { headers: { Authorization: `Bearer ${dashboardLayer.getToken()}` } } //todo: в стор еще никто не записал токен, как оно работать должно было?
          )
          .then((response) => {
            callback(false, response.data)
          })
          .catch((error) => {
            callback(true, error)
          })
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  const currentQuery = { ...to.query }
  const userId = from.query.user_id || to.query.user_id
  const tokenFromCRM = from.query.token || to.query.token
  const fromCRM = from.query.from_crm || to.query.from_crm
  const requireAuth = to.matched.some((record) => record.meta.auth)
  const token = dashboardLayer.getToken() || localStorage.getItem('token') || null

  const newQuery = { ...currentQuery }

  if (fromCRM == 'true' && tokenFromCRM && userId) {
    // eslint-disable-next-line camelcase
    dashboardLayer.setToken(tokenFromCRM)
    dashboardLayer.dashboardStore.$patch({
      'client_id': userId,
      'authClient': userId,
    })
    localStorage.setItem('auth:client', userId)
    newQuery.from_crm = fromCRM
  }

  if (tokenFromCRM) {
    newQuery.token = tokenFromCRM
  }

  if (requireAuth) {
    if (token?.length < 2) {
      notificationsLayer.addNotification({
        message: 'Токен доступа истёк, пройдите авторизацию повторно'
      })
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
})

app.use(i18n)
app.use(router).use(Maska).mount('#app')
