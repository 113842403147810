import {defineStore} from "pinia";

export const signupStore = defineStore('signUp', {
  state: () => ({
    csrf: '',
    token: '',
    // eslint-disable-next-line camelcase
    user_id: '',
    cards: [],
    step: 0,
    phone: '',
    isNewClient: undefined,
    isEmailExist: true,
    availableFileTypes: [],
    files: {},
    settings: undefined
  })
})
